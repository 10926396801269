<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <v-alert
          class="mx-2 white--text"
          border="left"
          colored-border
          :color="$store.state.secondaryColor"
        >
          <v-form ref="form">
            <v-layout column>
              <span class="black--text">{{ $t("package name") }} : </span>

              <v-menu
                offset-y
                :return-value.sync="selectedPackage"
                ref="mPackage"
              >
                <template v-slot:activator="{ on }">
                  <input
                    type="text"
                    v-on="on"
                    class="input-style caption my-2"
                    v-model="selectedPackage"
                  />
                </template>
                <v-list dense class="pa-0">
                  <div v-for="pack in packages" :key="pack.id">
                    <v-list-item @click="selectPackage(pack)">{{
                      pack.name
                    }}</v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list>
              </v-menu>

              <span class="black--text mt-4">{{ $t("owner's name") }} : </span>

              <input
                type="text"
                class="input-style caption my-2"
                readonly
                v-on="on"
                v-model="selectedOwner"
              />

              <v-btn
                :color="$store.state.primaryColor"
                :loading="loading"
                dark
                large
                class="my-2"
                @click="savePackage()"
              >
                <v-icon left small>save</v-icon>
                <span>{{ $t("update") }}</span>
              </v-btn>
            </v-layout>
          </v-form>
        </v-alert>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackWarning"
      top
      centered
      color="red lighten-5 error--text text--darken-3"
    >
      <span>{{ $t("there are empty fields") }}</span>

      <template v-slot:action="{ attr }">
        <v-btn
          icon
          color="error darken-3"
          v-bind="attr"
          @click="snackWarning = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackExists"
      top
      centered
      color="warning lighten-5 warning--text text--darken-3"
    >
      <span>{{ $t("owner already registered with license") }}</span>

      <template v-slot:action="{ attr }">
        <v-btn
          icon
          color="warning darken-3"
          v-bind="attr"
          @click="snackExists = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackSuccess"
      top
      centered
      color="success lighten-5 success--text text--darken-3"
    >
      <span>{{ $t("license registered") }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";
export default {
  data: () => ({
    logs: [],
    loading: false,
    selectedOwner: "",
    licenses: [],
    packages: [],

    selectedPackage: "",
    register: {
      packageId: "",
      ownerId: "",
      startDate: "",
      expiryDate: "",
      isValid: true,
    },

    snackExists: false,
    snackSuccess: false,
    snackloading: false,
    snackWarning: false,
  }),

  computed: {
    owners() {
      return this.ownerData.filter((val) => {
        return (
          val.names.first
            .toLowerCase()
            .includes(this.searchOwner.toLowerCase()) ||
          val.names.middle
            .toLowerCase()
            .includes(this.searchOwner.toLowerCase()) ||
          val.names.last.toLowerCase().includes(this.searchOwner.toLowerCase())
        );
      });
    },
  },

  created() {
    this.getPackage();
    this.getLicenses();
  },

  methods: {
    getPackage() {
      this.packages = [];
      db.collection("packages")
        .orderBy("level")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.packages.push({
              id: snaps.id,
              ...snaps.data(),
            });
          });
        });
    },

    getLicenses() {
      db.collection("licenses")
        .doc(this.$route.params.licenseId)
        .get()
        .then((snaps) => {
          this.register = snaps.data();
          this.getOwner(snaps.data().ownerId);
          this.setPackage(snaps.data().packageId);
        });
    },

    getOwner(ownerId) {
      db.collection("owners")
        .doc(ownerId)
        .get()
        .then((snapshot) => {
          this.selectedOwner = this.getFullnames(snapshot.data().names);
        });
    },

    setPackage(packageId) {
      db.collection("packages")
        .doc(packageId)
        .get()
        .then((snaps) => {
          this.selectedPackage = snaps.data().name;
        });
    },

    getFullnames(names) {
      return names.first + " " + names.middle.substr(0, 1) + ". " + names.last;
    },

    selectPackage(data) {
      this.$refs.mPackage.save(data.name);
      this.register.packageId = data.id;
    },

    DateFormat(data) {
      return data != null ? format(data.toDate(), "eee dd-MMM-yyyy hh:mm") : "";
    },

    savePackage() {
      if (this.register.packageId == "" || this.register.ownerId == "") {
        this.snackWarning = true;
      } else {
        this.loading = true;
        let day = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let nextYear = new Date().getFullYear() + 1;

        let newDate = nextYear + "-" + month + "-" + day;
        this.register.startDate = new Date();
        this.register.expiryDate = new Date(newDate);

        db.collection("licenses")
          .doc(this.$route.params.licenseId)
          .update(this.register)
          .then(() => {
            this.snackSuccess = true;
            this.loading = false;
            this.$router.push({ name: "Licenses" });
          });
      }
    },
  },
};
</script>
