var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{staticClass:"mx-2 white--text",attrs:{"border":"left","colored-border":"","color":_vm.$store.state.secondaryColor}},[_c('v-form',{ref:"form"},[_c('v-layout',{attrs:{"column":""}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t("package name"))+" : ")]),_c('v-menu',{ref:"mPackage",attrs:{"offset-y":"","return-value":_vm.selectedPackage},on:{"update:returnValue":function($event){_vm.selectedPackage=$event},"update:return-value":function($event){_vm.selectedPackage=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.selectedPackage),expression:"selectedPackage"}],staticClass:"input-style caption my-2",attrs:{"type":"text"},domProps:{"value":(_vm.selectedPackage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedPackage=$event.target.value}}},on))]}}])},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.packages),function(pack){return _c('div',{key:pack.id},[_c('v-list-item',{on:{"click":function($event){return _vm.selectPackage(pack)}}},[_vm._v(_vm._s(pack.name))]),_c('v-divider')],1)}),0)],1),_c('span',{staticClass:"black--text mt-4"},[_vm._v(_vm._s(_vm.$t("owner's name"))+" : ")]),_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOwner),expression:"selectedOwner"}],staticClass:"input-style caption my-2",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.selectedOwner)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedOwner=$event.target.value}}},_vm.on)),_c('v-btn',{staticClass:"my-2",attrs:{"color":_vm.$store.state.primaryColor,"loading":_vm.loading,"dark":"","large":""},on:{"click":function($event){return _vm.savePackage()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("save")]),_c('span',[_vm._v(_vm._s(_vm.$t("update")))])],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"red lighten-5 error--text text--darken-3"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attr = ref.attr;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackWarning = false}}},'v-btn',attr,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackWarning),callback:function ($$v) {_vm.snackWarning=$$v},expression:"snackWarning"}},[_c('span',[_vm._v(_vm._s(_vm.$t("there are empty fields")))])]),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"warning lighten-5 warning--text text--darken-3"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attr = ref.attr;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"warning darken-3"},on:{"click":function($event){_vm.snackExists = false}}},'v-btn',attr,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackExists),callback:function ($$v) {_vm.snackExists=$$v},expression:"snackExists"}},[_c('span',[_vm._v(_vm._s(_vm.$t("owner already registered with license")))])]),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"success lighten-5 success--text text--darken-3"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attr = ref.attr;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attr,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("license registered")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }